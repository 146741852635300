@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  font-family: "Gotham SSm A", "Gotham SSm B", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  color: #161E2E;
  margin: 0;
  padding: 0;
  line-height: 1.4em;
  color: #161E2E; }
  h1.centered, h2.centered, h3.centered, h4.centered, h5.centered, h6.centered {
    text-align: center; }
  h1.bold, h2.bold, h3.bold, h4.bold, h5.bold, h6.bold {
    font-weight: 700; }
  h1.medium, h2.medium, h3.medium, h4.medium, h5.medium, h6.medium {
    font-weight: 500; }
  h1.light, h2.light, h3.light, h4.light, h5.light, h6.light {
    font-weight: 300; }
  h1.fancy, h2.fancy, h3.fancy, h4.fancy, h5.fancy, h6.fancy {
    font-family: monospace; }

.headline-h0 {
  font-size: 48px;
  padding-bottom: 15px; }
  .headline-h0.underline {
    border-bottom: 1px solid #4B5563;
    margin-top: 15px; }

.headline-h1 {
  font-size: 36px;
  padding-bottom: 15px; }
  .headline-h1.underline {
    border-bottom: 1px solid #4B5563;
    margin-top: 15px; }

.headline-h2 {
  font-size: 32px;
  padding-bottom: 15px; }
  .headline-h2.underline {
    border-bottom: 1px solid #4B5563;
    margin-top: 15px; }

.headline-h3 {
  font-size: 30px;
  padding-bottom: 10px; }
  .headline-h3.underline {
    border-bottom: 1px solid #4B5563;
    margin-top: 10px; }

.headline-h4 {
  font-size: 26px;
  padding-bottom: 10px; }
  .headline-h4.underline {
    border-bottom: 1px solid #4B5563;
    margin-top: 10px; }

.headline-h5 {
  font-size: 22px;
  padding-bottom: 5px; }
  .headline-h5.underline {
    border-bottom: 1px solid #4B5563;
    margin-top: 5px; }

.headline-h6 {
  font-size: 18px;
  padding-bottom: 5px; }
  .headline-h6.underline {
    border-bottom: 1px solid #4B5563;
    margin-top: 5px; }
