@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.not-found-page {
  display: flex;
  flex-direction: column;
  flex: 1; }
  .not-found-page .error-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
