@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

* {
  font-family: "Gotham SSm A", "Gotham SSm B", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  box-sizing: border-box; }

html {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-family: "Gotham SSm A", "Gotham SSm B", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  color: #161E2E;
  line-height: 1.4em;
  background: #fff;
  min-height: 100%; }

#application-container {
  display: flex;
  flex-direction: column;
  flex: 1; }
