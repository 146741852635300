@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.paragraph {
  font-weight: 400;
  font-family: "Gotham SSm A", "Gotham SSm B", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  color: #161E2E;
  margin-top: 0;
  line-height: 19px; }
  .paragraph, .paragraph.regular {
    font-size: 16px; }
  .paragraph.small {
    font-size: 14px; }
  .paragraph.medium {
    font-size: 18px; }
  .paragraph.large {
    font-size: 22px;
    line-height: 1.2em; }
  .paragraph.x-large {
    font-size: 26px;
    line-height: 1.2em; }
  .paragraph.color-white {
    color: #fff; }
  .paragraph.color-gray {
    color: #6B7280; }
  .paragraph.weight-light {
    font-weight: 300; }
  .paragraph.weight-medium {
    font-weight: 500; }
  .paragraph.weight-bold {
    font-weight: 700; }
