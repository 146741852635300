@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.avatar {
  border-radius: 50%;
  background: #D2D6DD;
  color: rgba(255, 255, 255, 0.8);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  vertical-align: middle; }
  .avatar.small {
    width: 30px;
    height: 30px;
    font-size: 12px; }
  .avatar, .avatar.regular {
    width: 50px;
    height: 50px;
    font-size: 26px; }
  .avatar.large {
    width: 100px;
    height: 100px;
    font-size: 36px; }
  .avatar .image {
    height: 100%;
    width: 100%;
    background-size: cover; }
