@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.text {
  font-weight: 400;
  font-family: "Gotham SSm A", "Gotham SSm B", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  color: #161E2E;
  margin-top: 0;
  line-height: 19px; }
  .text, .text.regular {
    font-size: 16px; }
  .text.small {
    font-size: 14px; }
  .text.medium {
    font-size: 22px; }
  .text.large {
    font-size: 26px;
    line-height: 1.2em; }
  .text.x-large {
    font-size: 30px;
    line-height: 1.2em; }
  .text.weight-light {
    font-weight: 300; }
  .text.weight-regular {
    font-weight: 400; }
  .text.weight-medium {
    font-weight: 500; }
  .text.weight-bold {
    font-weight: 700; }
  .text.color-dark-gray {
    color: #374151; }
  .text.color-gray {
    color: #6B7280; }
  .text.style-italic {
    font-style: italic; }
