@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.tag-list {
  display: flex;
  flex-direction: column; }
  .tag-list .posts {
    display: flex;
    flex-direction: column; }
    .tag-list .posts .link {
      border-bottom: none;
      color: #4083F8; }
      .tag-list .posts .link:not(:last-of-type) {
        margin-bottom: 10px; }
      .tag-list .posts .link:hover {
        color: #77A9FA; }
