@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.archive-page {
  display: flex;
  flex-direction: column; }
  .archive-page .page-content {
    align-items: center; }
  .archive-page .archive-page-content {
    display: flex;
    flex-direction: column; }
  .archive-page .archive-post {
    display: flex;
    flex-direction: column; }
    .archive-page .archive-post:not(:last-of-type) {
      margin-bottom: 20px; }
    .archive-page .archive-post .link {
      border-bottom: none; }
