@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.tags-page {
  display: flex;
  flex-direction: column; }
  .tags-page .page-content {
    align-items: center;
    display: flex; }
  .tags-page .tags-page-content {
    margin: 40px 20px;
    max-width: 660px;
    width: 100%; }
    .tags-page .tags-page-content.has-match-tag .tag-list .tag-list-tag {
      display: none; }
    .tags-page .tags-page-content .tag-list:not(:last-of-type) {
      margin-bottom: 30px; }
