@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.post {
  display: flex;
  flex-direction: column; }
  .post .post-headline {
    display: flex;
    flex-direction: column; }
    .post .post-headline .post-title {
      display: flex;
      flex-direction: row; }
      .post .post-headline .post-title .avatar {
        margin-right: 20px;
        flex-shrink: 0;
        margin-top: 12px; }
      .post .post-headline .post-title .link {
        display: flex;
        justify-content: flex-start;
        border-bottom: none; }
        .post .post-headline .post-title .link:hover .headline {
          color: #4083F8; }
        .post .post-headline .post-title .link .headline {
          margin-bottom: 20px;
          padding-bottom: 0;
          display: flex;
          justify-content: flex-start; }
    .post .post-headline .post-meta {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 80px; }
      .post .post-headline .post-meta .avatar {
        margin-right: 10px; }
      .post .post-headline .post-meta .author-name {
        margin-right: 10px; }
  .post .post-body {
    font-size: 1.1em;
    line-height: 1.7em;
    margin-left: 80px;
    margin-top: 40px; }
    .post .post-body * {
      font-weight: 400;
      font-family: "Gotham SSm A", "Gotham SSm B", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
      font-size: 16px;
      color: #161E2E; }
    .post .post-body h1 {
      font-size: 42px;
      line-height: 46px;
      margin: 0;
      padding: 0; }
    .post .post-body h2 {
      font-size: 32px;
      line-height: 46px;
      margin: 0;
      padding: 0; }
    .post .post-body h3 {
      font-size: 28px;
      line-height: 34px;
      margin: 0;
      padding: 0; }
    .post .post-body h1, .post .post-body h2, .post .post-body h3, .post .post-body h4, .post .post-body h5, .post .post-body h6 {
      font-weight: 700; }
    .post .post-body ul,
    .post .post-body ol,
    .post .post-body dl {
      padding-left: 30px;
      margin: 15px 0; }
    .post .post-body li {
      margin: 5px 0; }
    .post .post-body dl {
      padding: 0; }
    .post .post-body dt {
      font-style: italic;
      margin: 15px 0 5px; }
    .post .post-body dd {
      font-style: normal;
      margin: 0 0 15px;
      padding: 0 15px; }
    .post .post-body pre,
    .post .post-body code {
      background-color: #F9FAFC;
      border: 1px solid #E6E7EB;
      font-size: 16px;
      line-height: 22px;
      overflow: auto;
      border-radius: 3px;
      font-family: monospace; }
    .post .post-body p code {
      display: inline-block;
      vertical-align: middle;
      font-size: 1em;
      padding: 0 5px;
      font-family: monospace; }
    .post .post-body pre code {
      background: transparent;
      border: none;
      margin: 0;
      padding: .5em;
      display: block;
      font-family: monospace; }
    .post .post-body pre * {
      font-family: monospace; }
    .post .post-body blockquote {
      border-left: 6px solid #1A56DB;
      padding: 0 15px;
      color: #374151;
      margin: 15px 0;
      margin-left: -26px;
      padding-left: 20px;
      font-style: italic; }
    .post .post-body table {
      padding: 0;
      border-collapse: collapse;
      font-size: 16px; }
    .post .post-body table tr {
      border-top: 1px solid #E6E7EB;
      background-color: #fff;
      margin: 0;
      padding: 0; }
    .post .post-body table tr:nth-child(2n) {
      background-color: #F4F5F7; }
    .post .post-body table tr th {
      font-weight: bold; }
    .post .post-body table tr th,
    .post .post-body table tr td {
      border: 1px solid #E6E7EB;
      text-align: left;
      margin: 0;
      padding: 6px 13px; }
    .post .post-body img {
      max-width: 550px;
      padding: 5px;
      border: 1px solid #F4F5F7;
      margin-top: 20px; }
    .post .post-body a {
      color: #4083F8;
      text-decoration: underline; }
      .post .post-body a:hover {
        color: #A4CAFF; }
    .post .post-body .hljs {
      display: block;
      overflow-x: auto;
      padding: .5em;
      color: #333;
      background: #f8f8f8; }
    .post .post-body .hljs-comment,
    .post .post-body .hljs-quote {
      color: #998;
      font-style: italic; }
    .post .post-body .hljs-keyword,
    .post .post-body .hljs-selector-tag,
    .post .post-body .hljs-subst {
      color: #333;
      font-weight: 700; }
    .post .post-body .hljs-literal,
    .post .post-body .hljs-number,
    .post .post-body .hljs-tag .hljs-attr,
    .post .post-body .hljs-template-variable,
    .post .post-body .hljs-variable {
      color: teal; }
    .post .post-body .hljs-doctag,
    .post .post-body .hljs-string {
      color: #d14; }
    .post .post-body .hljs-section,
    .post .post-body .hljs-selector-id,
    .post .post-body .hljs-title {
      color: #900;
      font-weight: 700; }
    .post .post-body .hljs-subst {
      font-weight: 400; }
    .post .post-body .hljs-class .hljs-title,
    .post .post-body .hljs-type {
      color: #458;
      font-weight: 700; }
    .post .post-body .hljs-attribute,
    .post .post-body .hljs-name,
    .post .post-body .hljs-tag {
      color: navy;
      font-weight: 400; }
    .post .post-body .hljs-link,
    .post .post-body .hljs-regexp {
      color: #009926; }
    .post .post-body .hljs-bullet,
    .post .post-body .hljs-symbol {
      color: #990073; }
    .post .post-body .hljs-built_in,
    .post .post-body .hljs-builtin-name {
      color: #0086b3; }
    .post .post-body .hljs-meta {
      color: #999;
      font-weight: 700; }
    .post .post-body .hljs-deletion {
      background: #fdd; }
    .post .post-body .hljs-addition {
      background: #dfd; }
    .post .post-body .hljs-emphasis {
      font-style: italic; }
    .post .post-body .hljs-strong {
      font-weight: 700; }
  .post .post-footer {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-left: 80px; }
    .post .post-footer .tags-list-wrapper {
      flex-direction: row;
      display: flex; }
      .post .post-footer .tags-list-wrapper .micon {
        margin-right: 10px;
        color: #6B7280; }
      .post .post-footer .tags-list-wrapper .link {
        color: #0594A2;
        border-bottom: none; }
        .post .post-footer .tags-list-wrapper .link:hover {
          color: #15BDCA; }
