@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 20px;
  background: #252F3F;
  color: #fff;
  text-align: center; }
  .footer .copyright {
    font-size: 12px; }
