@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.header {
  display: flex;
  flex-direction: column;
  background: #fff;
  z-index: 1;
  justify-content: center;
  align-items: center; }
  .header .header-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1160px;
    width: 100%; }
  .header .header-top {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px; }
    .header .header-top .link {
      border: none; }
    .header .header-top .left-side {
      display: flex;
      flex: 1;
      align-items: center; }
      .header .header-top .left-side .link:not(:last-of-type) {
        margin-right: 20px; }
    .header .header-top .right-side {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: flex-end; }
      .header .header-top .right-side .right-side-item {
        margin: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center; }
      .header .header-top .right-side .cart-link {
        color: #161E2E;
        display: flex; }
  .header .header-banner {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 10px;
    align-items: center;
    justify-content: center; }
    .header .header-banner .link {
      font-size: 36px;
      font-weight: 700;
      border-bottom: none;
      margin-bottom: 20px; }
