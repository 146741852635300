@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.page {
  display: flex;
  flex-direction: column;
  background: #fff;
  flex: 1;
  max-width: 100vw;
  overflow: hidden; }
  .page .page-content-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    margin-bottom: 40px; }
  .page .page-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 1160px;
    width: 100%; }
  .page .product-menu-container {
    margin: 0 10px; }
    @media only screen and (min-width: 1160px) {
      .page .product-menu-container {
        margin: 0; } }
