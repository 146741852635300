@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.post-page {
  display: flex;
  flex-direction: column; }
  .post-page .post-page-content {
    display: flex;
    align-items: center;
    flex-direction: column; }
  .post-page .post {
    margin: 40px 20px;
    max-width: 660px;
    width: 100%; }
  .post-page .comments-wrapper {
    display: flex;
    flex-direction: column;
    margin: 40px 20px;
    max-width: 660px;
    width: 100%; }
