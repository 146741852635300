@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.app-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1; }
  .app-wrapper .app-wrapper-container {
    display: flex;
    flex-direction: column;
    flex: 1; }
  .app-wrapper .app-wrapper-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: calc(100vw - 250px); }
  @media only screen and (min-width: 769px) {
    .app-wrapper .renew-session-modal .modal-wrapper {
      width: auto; } }
