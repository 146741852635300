@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.posts-page {
  display: flex;
  flex-direction: column; }
  .posts-page .page-content {
    align-items: center; }
  .posts-page .posts-page-content {
    display: flex;
    align-items: center;
    flex-direction: column; }
  .posts-page .post {
    margin: 40px 20px;
    max-width: 660px;
    width: 100%; }
  .posts-page .pagination-container {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    max-width: 660px;
    width: 100%;
    justify-content: center; }
    .posts-page .pagination-container .link {
      display: flex;
      padding: 0 7px;
      color: #4083F8;
      border-bottom: none;
      margin: 0 5px; }
      .posts-page .pagination-container .link.active {
        background: #4083F8;
        color: #fff;
        border-radius: 3px; }
      .posts-page .pagination-container .link:hover {
        color: #77A9FA; }
    .posts-page .pagination-container .prev {
      padding-right: 20px; }
    .posts-page .pagination-container .next {
      padding-left: 20px; }
