@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.link {
  color: #161E2E;
  text-decoration: none;
  border-bottom: 1px dotted #161E2E; }
  .link:hover {
    cursor: pointer;
    color: #374b73;
    text-decoration: none; }
  .link.underline, .link.underline:hover {
    text-decoration: underline; }
  .link.dark {
    color: #4083F8; }
    .link.dark:hover {
      color: #71a3fa; }
  .link.gray {
    color: #4B5563; }
    .link.gray:hover {
      color: #353c46; }
  .link.black {
    color: #161E2E; }
    .link.black:hover {
      color: #71a3fa; }
  .link.white {
    color: #fff; }
    .link.white:hover {
      color: white; }
  .link.tiny {
    font-size: 12px; }
  .link.small {
    font-size: 14px; }
  .link, .link.regular {
    font-size: 16px; }
  .link.medium {
    font-size: 18px; }
  .link.large {
    font-size: 22px; }
